import { TypeIdentifier } from './approval'
import { Organization } from './organization'
import { Role } from './role'

export class User {
  public static createFromJson(json: any): User {
    const organization = Organization.createFromJson(json.organization ?? {})
    const roles = (json.roles ?? []).map((it: any) => Role.createFromJson(it))
    return new User(
      json.id,
      json.name,
      json.picture,
      json.email,
      json.orgId,
      json.staffId,
      json.smartPhrId ?? null,
      json.isAdmin,
      json.isSmartPhrUser,
      json.commute ?? null,
      json.defaultWorkStartTime,
      json.activateCode ?? null,
      json.activateRequestAt ?? null,
      json.activateConfirmAt ?? null,
      organization,
      roles,
    )
  }

  constructor(
    public id: string,
    public name: string,
    public picture: string,
    public email: string,
    public orgId: string,
    public staffId: string,
    public smartPhrId: string | null,
    public isAdmin: boolean,
    public isSmartPhrUser: boolean,
    public commute: number | null,
    public defaultWorkStartTime: string,
    public activateCode: number | null,
    public activateConfirmAt: Date | null,
    public activateRequestAt: Date | null,
    public organization: Organization,
    public roles: Role[],
  ) {}
}

export type UserInput = {
  name: string
  email: string
  staffId: string
  isAdmin: boolean
  isSmartPhrUser: boolean
}

export type WorkDaysUserInput = {
  commute?: number | null
  defaultWorkStartTime?: string
}

export type WorkDaysInput = {
  uid: string
  commute: number | null
  defaultWorkStartTime: string
  add: Date[]
  remove: Date[]
}

export class Activate {
  public static createFromJson(json: any): Activate {
    const typeIdentifiers: TypeIdentifier[] = (json.typeIdentifiers ?? []).map((it: any) =>
      TypeIdentifier.createFromJson(it),
    )
    return {
      id: json.id,
      name: json.name,
      email: json.email,
      staffId: json.staffId ?? null,
      smartPhrId: json.smartPhrId ?? null,
      orgId: json.orgId,
      orgName: json.orgName,
      typeIdentifiers,
    }
  }

  constructor(
    public id: string,
    public name: string,
    public email: string,
    public staffId: string,
    public smartPhrId: string,
    public orgId: string,
    public orgName: string,
    public typeIdentifiers: TypeIdentifier[],
  ) {}
}
