import store from '@/store'
import { createAuthGuard } from '@auth0/auth0-vue'
import { App } from 'vue'
import { createRouter as createVueRouter, createWebHistory, Router } from 'vue-router'

export function createRouter(app: App): Router {
  const router = createVueRouter({
    routes: [
      {
        path: '/',
        component: () => import('@/views/public/Index.vue'),
      },
      {
        path: '/signup',
        beforeEnter: createAuthGuard(app),
        component: () => import('@/views/SignUp.vue'),
        meta: { title: '管理者サインアップ' },
      },
      {
        path: '/l',
        beforeEnter: createAuthGuard(app),
        component: () => import('@/views/_layout.vue'),
        children: [
          { path: '', redirect: '/l/dashboard' },
          { path: 'dashboard', component: () => import('@/views/Dashboard.vue'), meta: { title: 'ダッシュボード' } },
          { path: 'instruction', component: () => import('@/views/Instruction.vue'), meta: { title: '指示履歴' } },
          { path: 'user', component: () => import('@/views/User.vue'), meta: { title: '従業員管理' } },
          { path: 'user/add', component: () => import('@/views/UserAdd.vue'), meta: { title: '従業員登録' } },
          { path: 'group', component: () => import('@/views/Group.vue'), meta: { title: '部署管理' } },
          { path: 'group/add', component: () => import('@/views/GroupAdd.vue'), meta: { title: '部署登録' } },
          { path: 'calendar', component: () => import('@/views/Calendar.vue'), meta: { title: '勤務カレンダー' } },
          { path: 'settings', component: () => import('@/views/Settings.vue'), meta: { title: '組織設定' } },
          { path: 'notification', component: () => import('@/views/Notification.vue'), meta: { title: 'お知らせ' } },
        ],
      },
    ],
    history: createWebHistory(process.env.BASE_URL),
  })

  router.beforeEach(async to => {
    // フロントAPPバージョンのチェック（アップデートがあれば強制リロード）
    if (process.env.NODE_ENV === 'production') await store.dispatch('version', to.path)
  })

  return router
}
