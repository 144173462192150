export class Organization {
  public static createFromJson(json: any): Organization {
    return new Organization(json.id, json.name)
  }

  constructor(public id: string, public name: string) {}
}

export type OrganizationInput = {
  name: string
}
