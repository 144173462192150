import { Role } from './role'

export class Group {
  public static createFromJson(json: any): Group {
    const roles = (json.roles ?? []).map((it: any) => Role.createFromJson(it))
    const tags = (json.tags ?? []).map((it: any) => GroupTag.createFromJson(it))
    return new Group(json.id, json.name, roles, tags)
  }

  constructor(public id: string, public name: string, public roles: Role[], public tags: GroupTag[]) {}
}

export class GroupTag {
  public static createFromJson(json: any): GroupTag {
    return new GroupTag(json.id, json.name, true)
  }
  constructor(public id: number, public name: string, public active: boolean) {}
}

export type GroupInput = {
  name: string
}
