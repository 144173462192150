import api from '@/api'
import { User } from '@/models/user'
import { User as SmartPhrUser } from '@auth0/auth0-spa-js'
import { createStore } from 'vuex'

type State = {
  loading: boolean
  error: any
  token: string | null
  viewer: User | null
  version: string | null
  lastModified: Date | null
  profiles: User[]
}
const TOKEN_KEY = 'US_HMS_TOKEN'
const ORGANIZATION_KEY = 'ORGANIZATION'

export default createStore({
  state: {
    loading: false,
    error: null,
    token: localStorage.getItem(TOKEN_KEY) ?? null,
    viewer: null,
    version: null,
    lastModified: null,
    profiles: [],
  },

  getters: {
    isAuthenticated: (state: State) => !!state.profiles.length,
    loading: (state: State) => state.loading,
    viewer: (state: State) => state.viewer,
    error: (state: State) => state.error,
  },

  mutations: {
    loading(state: State, value: boolean) {
      state.loading = value
    },

    error(state: State, value: any) {
      state.error = value
    },

    token(state: State, token: string | null) {
      if (token) {
        localStorage.setItem(TOKEN_KEY, token)
      } else {
        localStorage.removeItem(TOKEN_KEY)
      }
      state.token = token
    },

    viewer(state: State, viewer: User) {
      localStorage.setItem(ORGANIZATION_KEY, viewer.organization.id)
      state.viewer = viewer
    },

    version(state: State, { version, lastModified }) {
      state.version = version
      state.lastModified = lastModified
    },

    profiles(state: State, profiles: User[]) {
      state.profiles = profiles
    },
  },

  actions: {
    async authenticate({ state, getters, dispatch }: { state: any; getters: any; dispatch: any }) {
      if (getters.isAuthenticated) return
      if (state.token) await dispatch('getProfiles')
    },

    async getProfiles({ dispatch, commit }: { dispatch: any; commit: any }, user: SmartPhrUser) {
      const profiles = await api
        .get('api/v1/profiles')
        .then((resp: any) => {
          return (resp.data ?? []).map((it: any) => {
            it.picture = user.picture
            return User.createFromJson(it)
          })
        })
        .catch(() => [])

      commit('profiles', profiles)

      if (profiles.length) {
        const selectedOrgId = localStorage.getItem(ORGANIZATION_KEY) ?? null
        await dispatch('setViewer', selectedOrgId || profiles[0].organization.id)
      }
    },

    async setViewer({ state, commit }: { state: any; commit: any }, id: string) {
      const viewer = state.profiles.find((profile: User) => profile.organization.id === id)
      commit('viewer', viewer ?? state.profiles[0])
    },

    async signUp({ commit }: { commit: any }, input: { userId: string; email: string; orgId: string }) {
      commit('loading', true)
      const result = await api
        .post('api/v1/admin/activate', {
          smartPhrId: input.userId,
          email: input.email,
          orgId: input.orgId,
        })
        .then((resp: any) => resp.data)
        .catch(() => null)
      commit('loading', false)

      return result
    },

    async setToken({ commit }: { commit: any }, user: SmartPhrUser) {
      commit('loading', true)
      const token = await api
        .post('api/v1/token', { smartPhrId: user.sub, email: user.email })
        .then((resp: any) => resp.data)
        .catch(() => null)
      commit('token', token)
      commit('loading', false)
    },

    async signIn({ dispatch, commit }: { dispatch: any; commit: any }, user: SmartPhrUser) {
      commit('loading', true)

      const token = await api
        .post('api/v1/token', { smartPhrId: user.sub, email: user.email })
        .then((resp: any) => resp.data)
        .catch(() => null)

      commit('token', token)

      if (token) await dispatch('getProfiles', user)

      commit('loading', false)
    },

    async signOut({ commit }: { commit: any }) {
      commit('loading', true)
      commit('token', null)
      commit('profiles', [])
      const url = `https://${process.env.VUE_APP_SMART_PHR_DOMAIN}/v2/logout`
      const query = `returnTo=${window.location.origin}&client_id=${process.env.VUE_APP_SMART_PHR_CLIENT_ID}`
      window.location.replace(`${url}?${query}`)
    },

    async version({ state, commit }: { state: any; commit: any }, toPath: (string | Location) & Location) {
      const options: any = { credentials: 'same-origin' }
      if (state.lastModified) options.headers = { 'If-Modified-Since': state.lastModified }

      const response = await fetch(`/version?t=${new Date().toISOString()}`, options)
      const responseText = await response.text()

      if (!state.version) {
        const lastVersion = localStorage.getItem('version')
        localStorage.setItem('version', responseText)

        if (lastVersion && lastVersion !== responseText) window.location = toPath

        commit('version', { version: responseText, lastModified: response.headers.get('last-modified') })
      } else if (response.status === 200) {
        if (responseText && responseText !== state.version) window.location = toPath
      }
    },
  },

  modules: {},
})
